import React from 'react';

import { TickerTape } from "react-ts-tradingview-widgets";

const TradingWidget = () => {
  
    
    
    
    return (

        <TickerTape colorTheme="light"></TickerTape>
    )
}

export default TradingWidget